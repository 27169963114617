import { ReactComponent as DatabaseIcon } from "assets/icon/database_yellow.svg"
import { Box, Icon, Typography } from "components/common"
import { env } from "configs"
import { EnumApprovalStatus } from "constants/enums/approval-status"

import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { Employee } from "constants/interface/employee"
import { WorkflowSeq } from "constants/interface/work-flow-seq"
import { get } from "utils/lodash"
import { useCallback, useMemo } from "react"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { employeeIsHr, formatDate } from "utils/helper"

const HistoryItemContainer = styled("div")<{ ["has-pointer"]: boolean }>`
  width: auto;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px;
  border: 0.5px solid ${AppColor["Gray/Flat Button Bg"]};
  cursor: ${(props) => (props["has-pointer"] ? "pointer" : "context")};
  box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
  &:hover {
    box-shadow: ${(props) => (props["has-pointer"] ? "-1px 0px 7px 4px rgba(142, 201, 231, 0.75)" : "none")};
  }
`

const Content = styled("div")`
  width: 100%;
  height: 100%;
`

const HeaderContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

const TypeContainer = styled("div")<{ color?: string }>`
  padding: 2px;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  height: fit-content;
  min-width: fit-content;
  margin-bottom: 8px;
`

const DetailContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  /* width: calc(100% - 16px); */
  height: auto;
  /* padding: 8px 8px 0px 8px;
  margin: 8px 0px 0px 0px; */
  margin-top: "8px";
  /* margin-bottom: 8px; */
  border-top: 1px solid ${AppColor["Text/Gray Success"]};
`

const RowFlex = styled("div")<{ mb?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.mb ? "8px" : "0px")};
`

const InsteadContainer = styled("div")`
  padding: 2px 4px;
  border-radius: 4px;
  background-color: ${AppColor["System/Warning Light"]};
  margin-bottom: 8px;
  margin-top: 8px;
`

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

interface ClaimRequestHistoryItemProps {
  id: string
  info: any
  workflowSeq?: WorkflowSeq[]
  createdAt: string
  referenceId: string
  amount: string
  owner: Employee
  createdBy: Employee
  onClick?: (id: string) => void
  userRole?: string
  status?: EnumClaimRequestStatus
  filteredStatus?: EnumClaimRequestStatus // use for claim-requests pages
  department?: string

  totalWorkflow?: number
  workflowApproved?: number
  myApprovalStatus?: EnumApprovalStatus

  benefitPlan?: string
}

const mapStatus = {
  [EnumClaimRequestStatus.DRAFT]: "แบบร่าง",
  [EnumClaimRequestStatus.APPROVED]: "อนุมัติ",
  [EnumClaimRequestStatus.REJECTED]: "ไม่อนุมัติ",
  [EnumClaimRequestStatus.WAITING]: "รออนุมัติ",
  [EnumClaimRequestStatus.CANCELED]: "",
}

export const ClaimRequestHistoryItem = (props: ClaimRequestHistoryItemProps) => {
  const {
    id,
    info,
    createdAt,
    referenceId,
    amount,
    owner,
    createdBy,
    onClick,
    status,
    filteredStatus,
    totalWorkflow = 0,
    workflowApproved = 0,
    userRole,
  } = props

  const { title, icon, type, iconColor, chipTitleColor, chipBackgroundColor } = info
  const claimCardDetails = JSON.parse(env.CLAIM_CARD_DETAILS)
  const name = `[${owner.employeeCode}] ${owner.firstName} ${owner.lastName}`
  const workflowLabel = useMemo(() => {
    if (filteredStatus) {
      // หน้ารายการเบิก

      if (filteredStatus === EnumClaimRequestStatus.APPROVED)
        return (
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">
              อนุมัติแล้ว({workflowApproved}/{totalWorkflow})
            </Typography>
          </Box>
        )

      return (
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1">
            {mapStatus[filteredStatus]}
            {filteredStatus !== EnumClaimRequestStatus.REJECTED && `(${workflowApproved}/${totalWorkflow})`}
          </Typography>
        </Box>
      )
    } else {
      // หน้าประวัติ
      if (status === EnumClaimRequestStatus.CANCELED)
        return (
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">ยกเลิก</Typography>
          </Box>
        )
      if (status === EnumClaimRequestStatus.REJECTED)
        return (
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">ไม่อนุมัติ</Typography>
          </Box>
        )
      if (status === EnumClaimRequestStatus.APPROVED)
        return (
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">
              {/* อนุมัติ({workflowApproved}/{totalWorkflow}) */}
              อนุมัติแล้ว
            </Typography>
          </Box>
        )
      return (
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1">
            รออนุมัติ({workflowApproved}/{totalWorkflow})
          </Typography>
        </Box>
      )
    }
  }, [filteredStatus, workflowApproved, totalWorkflow, status])

  const dateDisplay = useMemo(() => formatDate(createdAt, "dd/MM/yyyy"), [createdAt])

  const isInstead = useMemo(() => owner.id !== createdBy.id, [owner, createdBy])

  const handleClick = useCallback(() => {
    if (onClick) onClick(id)
  }, [onClick, id])

  return (
    <HistoryItemContainer has-pointer={!!onClick} onClick={handleClick}>
      <Content>
        <HeaderContainer>
          <Box display="flex" alignItems="center" justifyContent="flex-end" marginBottom="8px">
            <IconContainer>
              <IconBox>
                <Icon name={icon} color={iconColor} width="32px" height="32px" />
              </IconBox>
            </IconContainer>
            <Box>
              <Typography variant="body1">{title}</Typography>
            </Box>
          </Box>
          <TypeContainer color={chipBackgroundColor}>
            <Typography variant="caption" color={chipTitleColor}>
              {type}
            </Typography>
          </TypeContainer>
        </HeaderContainer>
        <DetailContainer style={{ marginBottom: "8px" }}>
          {isInstead && employeeIsHr(userRole) && (
            <InsteadContainer>
              <Typography variant="caption" color="Text/Primary Text">
                ทำแทนโดย [{createdBy.employeeCode}] {createdBy.firstName} {createdBy.lastName}
              </Typography>
            </InsteadContainer>
          )}
          <RowFlex mb style={{ marginTop: isInstead ? "0px" : "8px" }}>
            <Typography variant="subtitle1" color="Text/Placeholder">
              {dateDisplay}
            </Typography>
            <Typography variant="subtitle1" color="Text/Primary Text">
              {status === EnumClaimRequestStatus.DRAFT ? "แบบร่าง" : workflowLabel}
            </Typography>
          </RowFlex>
          <RowFlex>
            <Typography variant="subtitle1" color="Text/Placeholder">
              {referenceId}
            </Typography>
            <Box display="flex" alignItems="center">
              <DatabaseIcon width={12} height={12} fill={AppColor["Warning"]} style={{ marginRight: "6px" }} />
              <Typography variant="h6" color="Text/Primary Text">
                {amount}
              </Typography>
            </Box>
          </RowFlex>
        </DetailContainer>
        <DetailContainer>
          <Box style={{ margin: "8px 0px" }}>
            <RowFlex mb>
              <Typography variant="subtitle1" color="Text/Primary Text">
                {name}
              </Typography>
            </RowFlex>
            {claimCardDetails.length > 0 &&
              claimCardDetails.map((detail: any) => (
                <Box style={{ marginTop: "8px" }}>
                  <Typography variant="subtitle1" color="Text/Placeholder">
                    {get(owner, detail, "-")}
                  </Typography>
                </Box>
              ))}
          </Box>
        </DetailContainer>
      </Content>
    </HistoryItemContainer>
  )
}
